import Overview from "./components/overview";
import Download from "./components/download";
import { Route, Routes, Navigate, useNavigate, BrowserRouter } from "react-router-dom";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/download" element={<Download />} />
          <Route path="*" element={<Overview />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
