import DmanLogo from "../assets/images/dmanseclogo.svg"
import Dman from "../assets/images/dman.svg"
import Facebook from "../assets/images/facebook.svg";
import Twitter from "../assets/images/twitter.svg";
import Instagram from "../assets/images/instagram.svg";
let listcls = "text-[#fff] text-[16px] leading-[18px] tracking-[0.32px] cursor-pointer";

export default function Footer() {
    const scrollToElement = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            // element.style.scrollMarginTop = "100px";
            element.scrollIntoView({ top: "200", behavior: 'smooth' });
        }
    };
    return (<div className="background6">
        <div className="max-w-[100%] mx-auto px-10 lg:px-14 py-14">
            <div className="flex gap-20 lg:flex-row flex-col justify-between items-center">
                <div className="">
                    <div className="flex items-center gap-3 cursor-pointer" onClick={() => scrollToElement("home")}>
                        <div><img src={DmanLogo}></img></div>
                        <div><img src={Dman}></img>
                            <p className="text-[13.263px] font-medium  mt-[8px] text-[#fff]">Delivering Happiness</p>
                        </div>
                    </div>
                </div>
                <div className="flex gap-10 flex-wrap justify-center">
                    <div className={listcls} onClick={() => scrollToElement("home")}>Home</div>
                    <div className={listcls} onClick={() => scrollToElement("services")}>Our services</div>
                    <div className={listcls}>Vision</div>
                    <div className={listcls} onClick={() => scrollToElement("teams")}>Our team</div>
                    <div className={listcls}>About us</div>
                </div>
            </div>
            <div className="mt-16  mb-6 h-[1px] bg-[#6A6A6A]">
            </div>
            <div className="flex flex-col lg:flex-row justify-between items-center gap-2">
                <div className="text-[18px] leading-[18px] tracking-[0.32px] text-[#fff]">© {new Date().getFullYear()} D-man Pvt. Ltd</div>
                <div className="text-[#fff] flex gap-10">
                    <div className="text-[#fff] text-[16px] leading-[18px] cursor-pointer">Terms & conditions</div>
                    <div className="text-[#fff] text-[16px] leading-[18px] cursor-pointer">Privacy policy</div>
                    <div className="flex gap-4">
                        <a href="" target="_blank"><img src={Facebook} className="w-full h-full"></img></a>
                        <a href="" target="_blank"><img src={Twitter} className="w-full h-full"></img></a>
                        <a href="" target="_blank"><img src={Instagram} className="w-full h-full"></img></a>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}