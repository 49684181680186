import Image1 from "../assets/images/whoweare.svg"
export default function WhoWeAre() {
    return (<div className="background2">
        <div className="max-w-[100%] px-6 lg:px-0 mx-auto lg:mt-[-130px] pt-24 lg:pt-80 pb-36  relative z-[1]">
            <div className="flex flex-col justify-center items-center ">
                <div className="nav-text-color font-bold text-[50px]  leading-[50px] text-center">Who <span className="theme-text-color text-[70px] h-[70px] font-normal cover_font">we</span> are?</div>
                <div className="mt-[40px] text-center text-[#383839] text-[24px] leading-[40px] font-medium"> Revolutionizing the delivery ecosystem in tier II and tier III <br />
                    cities in  Tamilnadu by making it accessible to everyone.</div>
                <div className="mt-[80px] lg:w-[649px] lg:h-[342px]" onContextMenu={(e) => e.preventDefault()}>
                    <img src={Image1} className="w-full h-full object-cover" ></img>
                </div>
            </div>
            <div className="elipse1-bg z-[-1]"></div>
            <div className="elipse2-bg z-[-1]"></div>
        </div>
    </div>)
}