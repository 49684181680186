import DmanLogo from "../assets/images/dmanlogo.svg"
import Dman from "../assets/images/dman.svg"
import QRCode from "../assets/images/QRcode.svg"
import AppStore from "../assets/images/appstore.svg"
import PlayStore from "../assets/images/playstore.svg"
const Download = () => {
    return <div className="bg-[#F6F8FB]">
        <div className="max-w-[100%] mx-auto pt-24 pb-24 lg:px-16">
            <div className="flex justify-center items-center ">
                <div className="bg-[#fff] rounded-[20px] py-10  max-w-[808px] max-h-[689px] p-4 lg:p-14 flex flex-col justify-center items-center gap-5">
                    <div className="flex items-center gap-3">
                        <div><img src={DmanLogo}></img></div>
                        <div><img src={Dman}></img>
                            <p className="text-[13.263px] font-medium  mt-[8px] nav-text-color">Delivering Happiness</p>
                        </div>
                    </div>
                    <div className="text-[#212121] text-[24px] leading-[24px] font-semibold mt-4">Welcome to D-man</div>
                    <div className="bg-[#F2F2F2] h-[1px] w-full px-5"></div>
                    <div className="text-[#667085] text-[16px] leading-[30px] text-center">We are very grateful to have you as our customer. Please download oru app and
                        enjoy a intriguing  and a user friendly experience.</div>
                    <div><img src={QRCode}></img></div>
                    <div className="flex flex-col lg:flex-row gap-14 mt-5">
                        <div className="cursor-pointer"><img src={AppStore}></img></div>
                        <div className="cursor-pointer"><img src={PlayStore}></img></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default Download