
import Team1 from "../assets/images/Team1.svg"
import Team2 from "../assets/images/Team2.svg"
import Team3 from "../assets/images/Team3.svg"
import Team4 from "../assets/images/Team4.svg"
import advisor1 from "../assets/images/advisory1.svg"
import advisor2 from "../assets/images/advisory2.svg"
import LinkedIn from "../assets/images/linkedin.svg"
let teamList = [{ img: Team1, name: "Bhanu", job: "Software Tower Executive" }, { img: Team2, name: "Karthik Nanjareddy", job: "Software Tower Executive" }, { img: Team3, name: "Prabhakaran", job: "Delivery Executive Lead" }, { img: Team4, name: "Irfan J", job: "DE Engagement Officer" }]
let advisorList = [{ img: advisor1, name: "Prabhakaran", job: "Delivery Executive Lead" }, { img: advisor2, name: "Richard Wellington", job: "Sales Analyst Executive" }]
const Teams = () => {
    return <div className="bg-[#fff]">
        <div className="max-w-[100%] mx-auto pt-24 pb-15 px-10 lg:px-16">
            <div className="nav-text-color  text-[46px] lg:text-[56px] leading-[46px] lg:leading-[56px] font-bold text-center mb-10">Our <span className="theme-text-color cover_font text-[56px] leading-[56px] font-normal">Team</span> of D-man</div>
            <div className="flex justify-between gap-10 lg:gap-2 min-h-[300px] flex-col items-center lg:flex-row  lg:items-end flex-wrap  mb-32">
                {teamList.map((member) => <div className="">
                    <div className="flex justify-center mb-[-60px] ">
                        <div className="w-[160px] h-[160px] border-[4px] border-[#F3A101] rounded-[160px] z-10"><img src={member.img} className="w-inherit h-inherit object-cover" /></div>
                    </div>
                    <div className="relative min-w-[298px] max-w-[298px] min-h-[160px] max-h-[160px] teams-wrapper flex flex-col justify-end items-center py-4 px-3">
                        <div className="flex items-center gap-2">
                            <div><img src={LinkedIn} /></div>
                            {/* <div className="text-[#404040] text-[30px] leading-[30px] font-bold">{advisor.name}</div> */}
                            <div className="text-[#404040] text-[22px] leading-[22px] font-bold">{member.name ?? ""}</div>
                        </div>

                        <div className="text-[#404040] text-[18px] leading-[18px] mt-2">{member.job ?? ""}</div>
                    </div>
                </div>)}
            </div>
            <div className="nav-text-color text-[46px] lg:text-[56px] leading-[46px] lg:leading-[56px] font-bold text-center pt-5 pb-10">Our <span className="theme-text-color cover_font text-[56px] leading-[56px] font-normal">Advisory</span> of D-man</div>
            <div className="flex lg:flex-row flex-col flex-wrap gap-16 justify-center my-10">
                {advisorList.map((advisor) => <div className="flex flex-col gap-2 items-center">
                    <div className="w-[294px] h-[294px] border-[4px] border-[#F3A101] rounded-[160px] mb-4">
                        <img src={advisor.img} className="w-inherit h-inherit object-cover" />
                    </div>
                    <div className="flex items-center gap-2">
                        <div><img src={LinkedIn} /></div>
                        <div className="text-[#404040] text-[30px] leading-[30px] font-bold">{advisor.name}</div>
                    </div>
                    <div className="text-[#0077B5] text-[18px] leading-[19px]">{advisor.job}</div>
                </div>)}
            </div>
        </div>
    </div>

}
export default Teams