import BorderBackground from "../assets/images/ziczac.svg"
import circledesign from "../assets/images/Group.svg"
import DmanLogo from "../assets/images/dmanlogo.svg"
import Dman from "../assets/images/dman.svg"
import DownArrow from "../assets/images/download.png"
import BtnBackground from "../assets/images/btnbackground.svg"
import Home from "../assets/images/home2.png"
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Icon1 from "../assets/images/peoples.svg"
import Icon2 from "../assets/images/downloadIcon.svg"
import Icon3 from "../assets/images/users.svg"
import { Fragment, useState } from "react"
let navlist = [{ name: "Home", isActive: true, id: "home" }, { name: "Our services", active: false, id: "services" }, { name: "Vision", active: false }, { name: "Our Team", active: false, id: "teams" }, { name: "About us", active: false }]
export default function Navbar() {
    const navigate = useNavigate()
    const [navList, setNavList] = useState([...navlist])
    const [openNav, setOpenNav] = useState(false)
    const handleNavChange = (i, item) => {
        navList.forEach((el, ind) => { if (el.isActive) { navlist[ind].isActive = false } })
        navList[i].isActive = true
        setNavList([...navList])
        item?.id && scrollToElement(item.id)
    }

    const scrollToElement = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ top: "0", behavior: 'smooth' });
        }
    };
    return (
        <div className="background1">
            {/* header 1 */}
            <div className="w-full h-[56px]">
                <div className="w-full h-full relative">
                    <img src={BorderBackground} className="w-full h-full object-cover absolute top-0 left-0" alt=""></img>
                    <p className="absolute top-0 w-full h-full flex items-center justify-center">
                        <div className="text-[#fff] leading-4 text-[14px] md:text-[16px] text-center font-semibold">Thanks to all incredible customers and supporters.  We have delivered 1,00,000+ Orders in Sivaganga via Calls</div>
                    </p>
                </div>
            </div>
            {/* header 2*/}
            <div className="max-w-[100%] mx-auto lg:pl-[59px] px-6 md:pr-0 pt-10 lg:pb-0 pb-10 bg-set">
                {/* Navbar & Logo */}
                <div className="relative">
                    {/* <div className="absolute top-0 "><img src={circledesign}></img></div> */}
                    <div className="flex justify-between items-center relative">
                        <div className="flex items-center gap-3">
                            <div><img src={DmanLogo}></img></div>
                            <div><img src={Dman}></img>
                                <p className="text-[13.263px] font-medium  mt-[8px] nav-text-color">Delivering Happiness</p>
                            </div>
                        </div>
                        <div className=" hidden md:block ">
                            <ul className="flex flex-col md:flex-row gap-10 nav_list_wrapper mr-[150px]">
                                {navList.map((el, i) => <li className={"nav_each_list" + " " + `${el.isActive ? "active" : ""}`} onClick={() => handleNavChange(i, el)}>{el.name}</li>)}
                            </ul>
                        </div>

                        <div className="block md:hidden mr-2">
                            <button
                                className={
                                    openNav ? "navbar-toggler navbar-toggle-show" : "navbar-toggler"
                                }
                                type="button"
                                onClick={() => setOpenNav(!openNav)}
                            >
                                <span class="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        {openNav && <ul className="flex w-full flex-col items-center background1 md:hidden block md:flex-row gap-5 nav_list_wrapper absolute top-[100%]">
                            {navList.map((el, i) => <li className={"nav_each_list" + " " + `${el.isActive ? "active" : ""}`} onClick={() => { handleNavChange(i, el); setOpenNav(false) }}>{el.name}</li>)}
                        </ul>}
                    </div>


                </div>
                {/* Content of Home */}
                <div className="flex mt-[50px] md:mt-0 lg:items-center flex-col lg:flex-row">
                    <div className=" lg:w-1/2  banner-text">
                        <p className="title-text-color text-[40px] lg:text-[50px] md:leading-[70px] font-bold food_slide pr-[99px]">
                            Sivaganga’s most
                            Trusted Delivery service
                            to order
                            <span className="block lg:inline lg:ml-[10px] box b1  cover_font text-[66px] leading-[63px] font-normal theme-text-color  "> Food</span>
                            <span className="block lg:inline lg:ml-[10px] box b2  cover_font text-[50px] lg:text-[66px] leading-[63px] font-normal theme-text-color  "> Vegetables</span>
                            <span className="block lg:inline lg:ml-[10px] box b3  cover_font text-[50px] lg:text-[66px] leading-[63px] font-normal theme-text-color  "> Groceries</span>
                            <span className="block lg:inline lg:ml-[10px] box b4  cover_font text-[50px] lg:text-[66px] leading-[63px] font-normal theme-text-color  "> Medicines</span>
                            <span className="block lg:inline lg:ml-[10px] box b5  cover_font text-[50px] lg:text-[66px] leading-[63px] font-normal theme-text-color  "> Meat and seafoods</span>
                        </p>
                        <p className="default-text-color mt-[130px] lg:mt-[35px] font-medium text-[18px] leading-[32px] ">Remarkable results. Easier than email. Postcard marketing reinvented for modern ecommerce.</p>
                        <button className="mt-[20px] m-auto lg:mx-0 relative w-[215px] h-[60px] bg-[#434343] flex justify-center items-center gap-3 rounded download-btn">
                            <div className="w-[24px] h-[24px]"><img src={DownArrow} className="image-view" /></div>
                            <div className="text-[#fff] tracking-[0.32px] font-bold " onClick={() => navigate("/download")}>Download Now</div>
                        </button>
                    </div>
                    <div className="w-auto lg:w-1/2 xl:w-[755px] xl:h-[746] ml-auto">
                        <img src={Home} className="w-full h-full object-cover"></img>
                    </div>
                </div>
                {/* customers count */}
                <div className="mr-0">
                    <div className="flex flex-col justify-center items-center">
                        <div className="title-text-color text-[40px] lg:text-[54px]  nav-text-color font-bold ">Some <span className="cover_font font-normal theme-text-color leading-[54px] text-[54px]">number</span> that matter</div>
                        <div className="default-text-color font-medium text-[18px] lg:leading-[18px] mt-[12px]">In Sivaganga, we have taken the orders via calls and whatsapp messaging</div>
                    </div>
                    <div className="flex flex-col gap-3 lg:flex-row justify-between mt-[110px]  lg:pr-[59px]">
                        <div className="reviews_wrapper w-full min-h-[200px] lg:w-[390px] lg:h-[252px]">
                            <div className="review_title">28X</div>
                            <div className="review_desc">Orders Delivered</div>
                        </div>
                        <div className="reviews_wrapper w-full min-h-[200px] lg:w-[390px] lg:h-[252px]">
                            <div className="review_title">562.5K</div>
                            <div className="review_desc">Worth of essential delivery</div>
                        </div>
                        <div className="reviews_wrapper w-full min-h-[200px] lg:w-[390px] lg:h-[252px]">
                            <div className="review_title">17 Days</div>
                            <div className="review_desc">Customers</div>
                        </div>
                        {/* <div className="flex  gap-3 lg:gap-8">
                            <div className="w-[128px] h-[128px] flex_jc_ac bg-[#c06ef330] rounded-[40px]"><img src={Icon1}></img></div>
                            <div className="flex flex-col justify-around mt-[10px]"><div className="text-[#332C5C] font-semibold text-[48px] leading-[130%]">20k</div><div className="text-[#5E587A] text-[18px] font-normal leading-[180%] nunita_font">Total Delivery</div></div>
                        </div>

                        <div className="flex  gap-3 lg:gap-8">
                            <div className="w-[128px] h-[128px] flex_jc_ac bg-[#ffbb0729] rounded-[40px]"><img src={Icon2}></img></div>
                            <div className="flex flex-col justify-around mt-[10px]"><div className="text-[#332C5C] font-semibold text-[48px] leading-[130%]">30k</div><div className="text-[#5E587A] text-[18px] font-normal leading-[180%] nunita_font">Total downloads</div></div>
                        </div>
                        <div className="flex  gap-3 lg:gap-8">
                            <div className="w-[128px] h-[128px] flex_jc_ac bg-[#6dedc31a] rounded-[40px]"><img src={Icon3}></img></div>
                            <div className="flex flex-col justify-around mt-[10px]"><div className="text-[#332C5C] font-semibold text-[48px] leading-[130%]">10k</div><div className="text-[#5E587A] text-[18px] font-normal leading-[180%] nunita_font">Customer</div></div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>)
}