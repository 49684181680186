import Image from "../assets/images/services.png"
export default function Services() {
    return (<div className="bg-[#fff]">
        <div className="max-w-[100%] mx-auto pt-24 pb-36 lg:px-16">
            <div className="background5 rounded-[20px]  p-4 lg:p-14">
                <div className="text-[56px] font-bold leading-[56px] text-center">Our <span className="theme-text-color font-normal text-[56px] leading-[56px] cover_font">services</span></div>
                <div className="mt-[100px]">
                    <div className="flex flex-col gap-3 lg:gap-0 lg:flex-row items-center">
                        <div className="lg:w-1/2">
                            <div className="title-text-color  text-center lg:text-left text-[50px] font-bold leading-[50px] tracking-[-2px]">Grocery</div>
                            <div className="lg:w-3/4 text-center lg:text-left default-text-color mt-[15px] text-[20px] leading-[34px] font-medium">One place for Groceries, Medicines, Food and much more. Find everything you need in one app! Explore and shop for the best quality home and personal essentials.</div>
                        </div>
                        <div className="lg:w-[416px] lg:h-[501px] lg:ml-auto" onContextMenu={(e) => e.preventDefault()}>
                            <img src={Image} className="w-full h-full object-cover" ></img>
                        </div>
                    </div>
                </div>
                <div className="mt-[120px]">
                    <div className="flex flex-col flex-col-reverse lg:flex-row gap-3 lg:gap-0 lg:flex-row  items-center">
                        <div className="lg:w-[416px] lg:h-[501px]" onContextMenu={(e) => e.preventDefault()}>
                            <img src={Image} className="w-full h-full object-cover"></img>
                        </div>
                        <div className="lg:w-1/2 lg:ml-auto">
                            <div className="title-text-color  text-center lg:text-left  text-[50px] font-bold leading-[50px] tracking-[-2px]">Grocery & Essentials</div>
                            <div className="lg:w-[78%] text-center lg:text-left default-text-color mt-[15px] text-[20px] leading-[34px] font-medium">Explore the widest range of products. Checkout and choose from a variety of products available from stores across the city.</div>
                        </div>

                    </div>
                </div>

                <div className="mt-[120px]">
                    <div className="flex flex-col gap-3 lg:gap-0 lg:flex-row items-center">
                        <div className="lg:w-1/2">
                            <div className="title-text-color text-center lg:text-left text-[50px] font-bold leading-[50px] tracking-[-2px]">Stores</div>
                            <div className="lg:w-3/4 text-center lg:text-left default-text-color mt-[15px] text-[20px] leading-[34px] font-medium">Shop from local trusted stores around you. From fresh veggies to fashion, order from local stores you trust.</div>
                        </div>
                        <div className="lg:w-[416px] lg:h-[501px] lg:ml-auto">
                            <img src={Image} className="w-full h-full object-cover" onContextMenu={(e) => e.preventDefault()}></img>
                        </div>
                    </div>
                </div>

                <div className="mt-[120px]">
                    <div className="flex flex-col flex-col-reverse lg:flex-row gap-3 lg:gap-0 lg:flex-row items-center">
                        <div className="lg:w-[416px] lg:h-[501px]">
                            <img src={Image} className="w-full h-full object-cover" onContextMenu={(e) => e.preventDefault()}></img>
                        </div>
                        <div className="lg:w-1/2 lg:ml-auto">
                            <div className="title-text-color text-center lg:text-left text-[50px] font-bold leading-[50px] tracking-[-2px]">Payment</div>
                            <div className="lg:w-5/6 text-center lg:text-left default-text-color mt-[15px] text-[20px] leading-[34px] font-medium">Safe and Secure payments powered by Gpay, PhonePe and IppoPay. Get the same convenience, trust and security you get on every time you shop</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}