import Navbar from "./navbar"
import WhoWeAre from "./whoweare"
import HowitWorks from "./howitworks"
import LocationMap from "./locationmap"
import Teams from "./teams"
import Services from "./services"
import Footer from "./footer"
export default function Overview() {
    return (<><div id="home"><Navbar /></div>
        <div id="whoweare"><WhoWeAre /></div>
        <div id="howitworks"><HowitWorks /></div>
        <div id="locationonmap"><LocationMap /></div>
        <div id="teams"><Teams /></div>
        <div id="services"><Services /></div>
        <div id="footer"><Footer /></div></>)
}